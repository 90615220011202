

pre {
  background-color: $color-bg-lighter;
  border-left: 5px solid $color-divider;
  padding: 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: $color-text-light;
  font-size: 0.875rem;
}

code {
  //white-space: nowrap;
  color: #ba5e63;
}

.code-bold {
  color: #bd4147;
  font-weight: 500;
  letter-spacing: .5px;
}

kbd {
  background-color: $color-dark;
}

.pre-scrollable {
  max-height: 360px;
}


// Copy to clipboard button
//
.clipboard-copy {
  position: absolute;
  top: 10px;
  right: 13px;
  line-height: 20px;
  opacity: 0;
  z-index: 9;
  transition: opacity .5s;

  &:hover {
    text-decoration: none;
  }
}

pre:hover .clipboard-copy {
  opacity: 1;
  transition: transform 0s, opacity .5s;
}



// Prism
//
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background-color: $color-bg-lighter;
  padding-bottom: 12px;
  margin-top: 0;
  margin-bottom: 25px;
  //word-wrap: normal;
  //border: 1px solid $color-divider;
}

.line-numbers .line-numbers-rows {
  border-right-color: $color-divider;
}

.line-highlight {
  position: absolute;
  width: unquote("calc(100% - 3.8em)");
  margin-top: 13px;
  background: rgba(255,255,0,.1);
}

.token.badge {
  font-size: 100%;
  padding: 0;
}




// Code preview
//
.code-preview {
  border: 1px solid $color-divider;
  border-bottom: none;
  padding: 20px;
  background-color: #fff;

  > *:last-child {
    margin-bottom: 0;
  }

  + pre,
  + .code-toolbar > pre {
    border: 1px solid $color-divider;
  }
}




// Code title
//
.code-title {
  background-color: $color-bg-lighter;
  border: 1px solid $color-divider;
  border-bottom: none;
  padding: 15px 20px;
  margin-bottom: 0;

  > *:last-child {
    margin-bottom: 0;
  }
}




// Code wrapper
//
.code {

  margin-bottom: 30px;

  &.code-fold pre {
    display: none;
  }

  pre {
    margin-bottom: 0;
    //display: none;
  }

  &.show-language .prism-show-language {
    display: block;
  }
}

.code-card {
  .code-title {
    //font-family: $font-body;
  }

  .code-preview {
    padding: 0;
  }
}


// Pre toggler
//
.code-toggler {
  border-top: 1px solid $color-divider;
  margin-top: -1px;
  text-align: right;

  .btn {
    border-radius: 0;
    background-color: $color-bg-lighter;
    border-top: none;
    color: $color-text-secondary;

    i {
      vertical-align: middle;
    }
  }
}





// Dark style
//
.code-dark code[class*="language-"],
pre[class*="language-"].code-dark {
  color: #ccc;
  text-shadow: none !important;
  border-left: 0;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"].code-dark {
  background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

.code-dark {

  .line-numbers-rows {
    border-right-color: #444;
  }

  ~ .toolbar span {
    color: #ccc !important;
  }

  .token.comment,
  .token.block-comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #999;
  }

  .token.punctuation {
    color: #ccc;
  }

  .token.tag,
  .token.attr-name,
  .token.namespace,
  .token.deleted {
    color: #e2777a;
  }

  .token.function-name {
    color: #6196cc;
  }

  .token.boolean,
  .token.number,
  .token.function {
    color: #f08d49;
  }

  .token.property,
  .token.class-name,
  .token.constant,
  .token.symbol {
    color: #f8c555;
  }

  .token.selector,
  .token.important,
  .token.atrule,
  .token.keyword,
  .token.builtin {
    color: #cc99cd;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.regex,
  .token.variable {
    color: #7ec699;
  }

  .token.operator,
  .token.entity,
  .token.url {
    color: #67cdcc;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .token.inserted {
    color: green;
  }


}
