
.alert {
  border-width: 0;
  border-left-width: 4px;
  border-radius: 0;
}

.alert-link {
  font-weight: 500;
}
