/*------------------------------------
- Table of content
------------------------------------*/

.toc {
  position: relative;
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 1.0625rem;

  a {
    color: $color-text;
    transition: .3s ease;

    &:hover,
    &:active {
      text-decoration: none;
      color: $color-primary;
    }
  }

  li::before {
    content: "#";
    margin-right: 5px;
    color: $color-primary;
    opacity: 0.6;
  }

  ol {
    list-style: none;
    padding-left: 40px;
    font-size: 95%;
    opacity: 0.97;
  }

  &::before {
    content: attr(data-caption);
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $color-text-secondary;
    margin-bottom: 0.5rem;
  }
}



.toc-numbered {
  counter-reset: toc1;

  > li::before {
    content: counter(toc1) ". ";
    counter-increment: toc1;
  }

  > li > ol {
    counter-reset: toc2;

    > li::before {
      content: counter(toc1) "." counter(toc2) ". ";
      counter-increment: toc2;
    }

    > li > ol {
      counter-reset: toc3;

      > li::before {
        content: counter(toc1) "." counter(toc2) "." counter(toc3) ". ";
        counter-increment: toc3;
      }
    }
  }

}
