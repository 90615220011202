/*------------------------------------
- Color palette
------------------------------------*/

.color-palette-circular {

  list-style: none;
  padding-left: 0;
  margin: 1.5rem 0;

  li {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
    border-radius: 100%;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
  }
}

.color-palette-stacked {

  list-style: none;
  padding-left: 0;
  margin: 1.5rem 0;

  li {
    display: block;
    padding: 1rem 1.25rem;
    color: #fff;
    font-weight: 400;
    letter-spacing: 1px;
  }

}
